import React, { useEffect, useRef } from 'react';

const CursorAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let width = (canvas.width = window.innerWidth);
      let height = (canvas.height = window.innerHeight);
      let particles = [];

      // Handle window resize
      const handleResize = () => {
        width = canvas.width = window.innerWidth;
        height = canvas.height = window.innerHeight;
      };
      window.addEventListener('resize', handleResize);

      // Particle constructor
      function Particle(x, y) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 5 + 1;
        this.speedX = Math.random() * 3 - 1.5;
        this.speedY = Math.random() * 3 - 1.5;
        this.color = 'rgba(0, 255, 127, 1)'; // Bright green color
      }

      Particle.prototype.update = function () {
        this.x += this.speedX;
        this.y += this.speedY;
        this.size *= 0.90; // Shrink over time
      };

      Particle.prototype.draw = function () {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      };

      // Handle mouse movement
      const handleMouseMove = (event) => {
        for (let i = 0; i < 3; i++) {
          particles.push(new Particle(event.clientX, event.clientY));
        }
      };
      window.addEventListener('mousemove', handleMouseMove);

      // Animation loop
      const animate = () => {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'; // Slight trail effect
        ctx.fillRect(0, 0, width, height);
        particles.forEach((particle, index) => {
          particle.update();
          particle.draw();
          if (particle.size < 0.5) {
            particles.splice(index, 1);
          }
        });
        requestAnimationFrame(animate);
      };
      animate();

      // Cleanup event listeners on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);

    return <canvas ref={canvasRef} className="canvas" />;
  };

export default CursorAnimation;

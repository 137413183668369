import '../styles/landingPage.css';
import Button from './button';

const LandingPage = () => {
  const handleSignUpClick = () => {
    console.log('Main Sign Up clicked');
  };
  
    return (
      <div className="landing-page">
        <main className="main-content">
        <Button name="Sign Up for Beta →" className="sign-up-button" onClick={handleSignUpClick}></Button>
        </main>
      </div>
    );
  };
  
  export default LandingPage;
  